import React from 'react';
import { ImageViewerContainer } from './styles';
import { Viewer } from 'components/viewer';
import { useSelector } from 'react-redux';
import { selectPackageDetails } from 'store/package-details/selectors';
import { useParams } from 'react-router';
import { selectPackageById } from 'store/packages/selectors';
import { PackageCategoryEnum } from 'store/packages/types';

export const ImageViewer = (): JSX.Element => {
  const { packageId } = useParams();
  const { label_url: outgoingPackageLabelUrl } = useSelector(
    (state) => selectPackageById(state, PackageCategoryEnum.OUTGOING, packageId),
  ) || {};
  const { label_url } = useSelector(selectPackageDetails) || {};
  const url = label_url || outgoingPackageLabelUrl;

  return (
    <ImageViewerContainer data-testid="image-viewer-container" data-cy="image-viewer-container">
      <Viewer url={url} />
    </ImageViewerContainer>
  );
};
