import React from 'react';
import {
  StyledIFrame, PDFViewerContainer, ClickedArea, StyledImage,
} from './styles';
import { Spinner } from '../spinner';
import { checkUrlExtension } from 'utils/checkUrlExtension';
import { useViewer } from './hooks/use-viewer.hook';

interface PDFViewerProps {
  url: string;
  isPackageDetailsPage?: boolean;
  onClick?: VoidFunction;
}

export const Viewer = ({ url, isPackageDetailsPage = false, onClick }: PDFViewerProps): JSX.Element => {
  const shouldRenderIframe = checkUrlExtension(url, 'pdf');
  const {
    imgRef, isLoading, handleLoad, handleTap, customURLParams,
  } = useViewer();

  return (
    <PDFViewerContainer
      data-testid="viewer-container"
      data-cy="viewer-container"
      isPackageDetailsPage={isPackageDetailsPage}
    >
      {isLoading && <Spinner />}
      <ClickedArea onClick={onClick}>
        {shouldRenderIframe ? (
          <StyledIFrame
            title="PDF Viewer"
            src={`${url}${customURLParams}`}
            onLoad={handleLoad}
            data-testid="pdf-viewer-iframe"
            data-cy="pdf-viewer-iframe"
            isLoading={isLoading}
            isPackageDetailsPage={isPackageDetailsPage}
          />
        ) : (
          <StyledImage
            ref={imgRef}
            src={url}
            alt="package-image"
            onLoad={handleLoad}
            onClick={handleTap}
            data-testid="image-viewer"
            data-cy="image-viewer"
          />
        )}
      </ClickedArea>

    </PDFViewerContainer>
  );
};
