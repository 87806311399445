import styled from 'styled-components';

export const StyledPackageItemTile = styled.button`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
  border: 1px solid ${({ theme }) => theme.colors.palette.systemGreyLight};
  box-shadow: 0px 2px 10px 0px ${({ theme }) => theme.colors.greys.darkGrey7}33;

  &:hover {
    cursor: pointer;
  }
`;
