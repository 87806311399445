import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { PackagesState } from './types';

const initialState: PackagesState = {
  incoming: {},
  outgoing: {},
  getInfinitePackages: {
    status: null,
    error: null,
  },
  total: null,
  count: null,
};

const getPackagesRequestHandler = (
  state: PackagesState,
  { payload }: ActionType<typeof actions.getPackages.request>,
): PackagesState => ({
  ...state,
  [payload.type]: {
    ...state[payload.type],
    [payload.indexOfSet]: null,
  },
  getInfinitePackages: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getPackagesSuccessHandler = (
  state: PackagesState,
  { payload }: ActionType<typeof actions.getPackages.success>,
): PackagesState => ({
  ...state,
  [payload.type]: { ...state[payload.type], [payload.indexOfSet]: payload.packages },
  getInfinitePackages: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
  total: payload.total,
  count: payload.count,
});

const getPackagesFailureHandler = (
  state: PackagesState,
  { payload: error }: ActionType<typeof actions.getPackages.failure>,
): PackagesState => ({
  ...state,
  getInfinitePackages: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetPackagesHandler = (
  state: PackagesState,
): PackagesState => ({
  ...state,
  incoming: {},
  outgoing: {},
  getInfinitePackages: {
    error: null,
    status: null,
  },
  count: null,
  total: null,
});

const packagesReducer = createReducer(initialState)
  .handleAction(actions.getPackages.request, getPackagesRequestHandler)
  .handleAction(actions.getPackages.success, getPackagesSuccessHandler)
  .handleAction(actions.getPackages.failure, getPackagesFailureHandler)
  .handleAction(actions.resetPackages, resetPackagesHandler);

export default packagesReducer;
