import { PackageListSkeleton } from 'components/packages-list-content/package-list-skeleton/package-list-skeleton';
import { PackagesSet } from './packages-set';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectGetPackagesStatus, selectPackages, selectPaginationParams } from 'store/packages/selectors';
import { PackageCategoryEnum } from 'store/packages/types';
import {
  DEFAULT_QUANTITY_PACKAGES_SKELETON,
  EXTRA_CONTENT_HEIGHT,
  PACKAGE_TILE_HEIGHT,
} from 'components/packages-list-content/package-list-content.const';
import { ACTION_STATUSES } from 'shared/consts';

interface InfiniteContentProps {
  packageCategory: PackageCategoryEnum;
}

export const InfiniteContent = ({ packageCategory }: InfiniteContentProps) => {
  const packages = useSelector((state) => selectPackages(state, packageCategory));

  const { count, total } = useSelector(selectPaginationParams);
  const initialSkeletonQuantity = Math.round(
    Math.abs((document.documentElement?.clientHeight ?? 0 - EXTRA_CONTENT_HEIGHT) / PACKAGE_TILE_HEIGHT),
  );

  const isPending = useSelector(selectGetPackagesStatus) === ACTION_STATUSES.PENDING;
  const isInitialPackageLoading = count === null && total === null;

  return (
    <div>
      {Object.keys(packages)?.map((indexOfSet) => (
        <PackagesSet packagesSet={packages[+indexOfSet] ?? []} key={+indexOfSet} packageCategory={packageCategory} />
      ))}
      {isPending && (
        <PackageListSkeleton
          tileQuantity={isInitialPackageLoading ? initialSkeletonQuantity : DEFAULT_QUANTITY_PACKAGES_SKELETON}
        />
      )}
    </div>
  );
};
