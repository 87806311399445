import { StateType } from 'typesafe-actions';
import BuildingReducer from 'store/building/reducer';
import { combineReducers } from 'redux';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import themeReducer from 'store/theme/reducer';
import userReducer from 'store/user/reducer';
import uiMetadataReducer from 'store/ui-metadata/reducer';
import packagesReducer from 'store/packages/reducer';
import packageDetailsReducer from 'store/package-details/reducer';

const rootReducer = combineReducers({
  config: configReducer,
  user: userReducer,
  theme: themeReducer,
  errors: errorsReducer,
  building: BuildingReducer,
  uiMetadata: uiMetadataReducer,
  packages: packagesReducer,
  packageDetails: packageDetailsReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
