import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { PackageDetailsState } from './types';

const initialState: PackageDetailsState = {
  package: null,
  getPackageDetails: {
    status: null,
    error: null,
  },
};

const getPackageRequestHandler = (state: PackageDetailsState): PackageDetailsState => ({
  ...state,
  getPackageDetails: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getPackageSuccessHandler = (
  state: PackageDetailsState,
  { payload }: ActionType<typeof actions.getPackageDetails.success>,
): PackageDetailsState => ({
  ...state,
  package: payload.package,
  getPackageDetails: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getPackageFailureHandler = (
  state: PackageDetailsState,
  { payload: error }: ActionType<typeof actions.getPackageDetails.failure>,
): PackageDetailsState => ({
  ...state,
  getPackageDetails: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetPackageHandler = (state: PackageDetailsState): PackageDetailsState => ({
  ...state,
  package: null,
  getPackageDetails: {
    error: null,
    status: null,
  },
});

const packageDetailsReducer = createReducer(initialState)
  .handleAction(actions.getPackageDetails.request, getPackageRequestHandler)
  .handleAction(actions.getPackageDetails.success, getPackageSuccessHandler)
  .handleAction(actions.getPackageDetails.failure, getPackageFailureHandler)
  .handleAction(actions.resetPackageDetails, resetPackageHandler);

export default packageDetailsReducer;
