import { Container } from 'hocs/shared-styles';
import React from 'react';
import { Router } from 'components/router';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { useBuilding } from 'hooks/use-building.hook';
import { useViewParams } from 'hooks/use-view-params.hook';
import { useSdkData } from 'hooks/use-sdk-data.hook';
import { useAnalytics } from 'hooks/use-analytics.hook';

export const App: React.FC = (): JSX.Element => {
  useSdkData();
  useAnalytics();

  const shouldWaitForLogin = useExternalLogin();
  useBuildingTheme();
  const building = useBuilding();
  const params = useViewParams();

  const hasBuilding = building || params.buildingUuid;
  if (shouldWaitForLogin && !hasBuilding) {
    return (
      <Container isLoading>
        <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
      </Container>
    );
  }

  return <Router />;
};
