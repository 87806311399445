import { ExternalAuthPayload, GetCurrentUserPayload, UserLoginResponse } from './types';
import { FailureActionPayload } from '../errors/types';
import { createAsyncAction } from 'typesafe-actions';

export const externalLogin = createAsyncAction(
  'user/EXTERNAL_LOGIN_REQUEST',
  'user/EXTERNAL_LOGIN_RESPONSE',
  'user/EXTERNAL_LOGIN_FAILURE',
)<ExternalAuthPayload, UserLoginResponse, FailureActionPayload>();

export const getCurrentUser = createAsyncAction(
  'user/GET_CURRENT_REQUEST',
  'user/GET_CURRENT_RESPONSE',
  'user/GET_CURRENT_FAILURE',
)<GetCurrentUserPayload, Partial<Pick<UserLoginResponse, 'user'>>, FailureActionPayload>();
