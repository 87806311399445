import * as BuildingEpics from 'store/building/epics';

import * as routerEpics from 'store/router/epics';
import * as errorsEpics from 'store/errors/epics';
import * as themeEpics from 'store/theme/epics';
import * as userEpics from 'store/user/epics';
import * as uiMetadataEpics from 'store/ui-metadata/epics';
import * as packagesEpics from 'store/packages/epics';
import * as packageDetailsEpics from 'store/package-details/epics';

import { combineEpics } from 'redux-observable';

export default combineEpics(
  ...Object.values(routerEpics),
  ...Object.values(userEpics),
  ...Object.values(themeEpics),
  ...Object.values(errorsEpics),
  ...Object.values(BuildingEpics),
  ...Object.values(uiMetadataEpics),
  ...Object.values(packagesEpics),
  ...Object.values(packageDetailsEpics),
);
