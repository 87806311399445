import React from 'react';
import { useIntl } from 'react-intl';
import { Body, StyledContainer, Title } from './styles';

interface EmptyListPlaceholderProps {
  packageCategory: string;
}

export const EmptyListPlaceholder = ({ packageCategory }: EmptyListPlaceholderProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledContainer data-testid="empty-list-container">
      <Title>{intl.formatMessage({ id: 'emptyListPlaceholderTitle' }, { packageCategory })}</Title>
      <Body>{intl.formatMessage({ id: 'emptyListPlaceholderBody' }, { packageCategory })}</Body>
    </StyledContainer>
  );
};
