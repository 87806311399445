import { AjaxResponse, ajax } from 'rxjs/ajax';

import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { CurrentUserResponse } from 'store/user/types';
import { OwnerParams } from 'shared/types';
import { UiMetadata } from 'store/ui-metadata/types';
import { PACKAGE_MANAGEMENT } from 'shared/consts';
import { Observable } from 'rxjs';
import qs from 'qs';
import { GetPackagesAPIResponse, GetPackagesParams } from 'store/packages/types';
import { GetPackageDetailsParams, GetPackageDetailsAPIResponse } from 'store/package-details/types';
import { v4 as uuidv4 } from 'uuid';

export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private appInstanceConfigUuid?: string | null,
  ) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      'Hqo-Trace-Id': uuidv4(),
    };
  }

  private getQueryParams(params?: Record<string, string | number>): string {
    return qs.stringify(
      { appInstanceConfigUuid: this.appInstanceConfigUuid, ...params },
      { addQueryPrefix: true, skipNulls: true },
    );
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/auth/current${this.getQueryParams()}`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme${this.getQueryParams()}`, this.DefaultHeaders);
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(
      `${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}${this.getQueryParams({ include: 'languages' })}`,
      this.DefaultHeaders,
    );
  }

  fetchUiMetadata({ ownerType, ownerId }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    const queryParams = this.getQueryParams();

    return ajax.get(
      `${
        this.baseUrl
      }/api/verticals/${PACKAGE_MANAGEMENT}/${ownerType.toUpperCase()}/${ownerId}/ui-metadata${queryParams}`,
      this.DefaultHeaders,
    );
  }

  getPackages({
    ownerType,
    ownerId,
    type,
    limit,
    offset,
  }: GetPackagesParams): Observable<AjaxResponse<GetPackagesAPIResponse>> {
    return ajax.get(
      `${this.baseUrl}/package-management/v1/${ownerType}/${ownerId}/${type}-packages${this.getQueryParams({
        limit,
        offset,
      })}`,
      this.DefaultHeaders,
    );
  }

  getPackageDetails({
    ownerType,
    ownerId,
    packageId,
    type,
  }: GetPackageDetailsParams): Observable<AjaxResponse<GetPackageDetailsAPIResponse>> {
    return ajax.get(
      `${this.baseUrl}/package-management/v1/${ownerType}/${ownerId}/${type}-packages/${packageId}`,
      this.DefaultHeaders,
    );
  }
}
