import { PackageItem } from 'components/packages-list-content/packages/components/package-item';
import React from 'react';

import { Package, PackageCategoryEnum } from 'store/packages/types';

export interface PackagesSetProps {
  packageCategory: PackageCategoryEnum;
  packagesSet: Array<Package>
}

export const PackagesSet = ({ packagesSet, packageCategory }: PackagesSetProps) => (
  <div>
    {packagesSet.map((packageItem) => (
      <PackageItem key={packageItem.id} packageItem={packageItem} packageCategory={packageCategory} />
    ))}
  </div>
);
