import * as BuildingActions from 'store/building/actions';
import * as configActions from 'store/config/actions';
import * as errorsActions from 'store/errors/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';
import * as routerActions from 'store/router/actions';
import * as uiMetadataActions from 'store/ui-metadata/actions';
import * as packagesActions from 'store/packages/actions';
import * as packageDetailsActions from 'store/package-details/actions';

import { ActionType } from 'typesafe-actions';

const actions = {
  router: routerActions,
  user: userActions,
  theme: themeActions,
  config: configActions,
  errors: errorsActions,
  Building: BuildingActions,
  uiMetadata: uiMetadataActions,
  packages: packagesActions,
  packageDetails: packageDetailsActions,
};

export type RootAction = ActionType<typeof actions>;
export default actions;
