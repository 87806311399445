import React from 'react';
import { StyledCircledCheckmark, ToastMessageContainer } from './styles';
import { useIntl } from 'react-intl';
import { ToastMessage } from '@hqo/react-components-library/dist/molecules/toast-message-v2';

interface TrackingNumberToastProps {
  toggleToastVisibility: VoidFunction;
}

export const TrackingNumberToast = ({ toggleToastVisibility }: TrackingNumberToastProps): JSX.Element => {
  const intl = useIntl();

  return (
    <ToastMessageContainer data-testid="toast-message-container" data-cy="toast-message-container">
      <ToastMessage
        icon={<StyledCircledCheckmark />}
        text={intl.formatMessage({ id: 'trackingNumberCopied' })}
        onClose={toggleToastVisibility}
      />
    </ToastMessageContainer>
  );
};
