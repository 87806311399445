import { EmptyListPlaceholder } from 'components/packages-list-content/empty-list-placeholder/empty-list-placeholder';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PackageCategoryEnum } from 'store/packages/types';
import { selectPackages } from 'store/packages/selectors';
import { InfiniteContent } from 'components/packages-list-content/packages/components/infinite-content';
import { useInfiniteContent } from './components/infinite-content/hooks/use-infinite-content.hook';
import { PackagesListContainer } from 'components/packages-list-content/packages/styles';
import {
  INITIAL_PACKAGES_SET,
} from 'components/packages-list-content/package-list-content.const';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { track } from '@hqo/web-tracking';

interface PackagesProps {
  packageCategory: PackageCategoryEnum;
}

export const Packages = ({ packageCategory }: PackagesProps): JSX.Element => {
  const packages = useSelector((state) => selectPackages(state, packageCategory));
  useInfiniteContent({ packageCategory });

  useEffect(() => {
    if (Object.keys(packages).length === 1 && packages[INITIAL_PACKAGES_SET] !== null) {
      track(
        TRACK_EVENT_NAMES.PACKAGE_LIST_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          package_count: packages[INITIAL_PACKAGES_SET].length,
          page: packageCategory === PackageCategoryEnum.INCOMING ? 'delivered' : 'drop_offs',
        },
        { sendToHqoTracking: true },
      );
    }
  }, [packages]);

  if (Object.keys(packages).length === 0 || packages[INITIAL_PACKAGES_SET]?.length === 0) {
    return <EmptyListPlaceholder packageCategory={packageCategory} />;
  }

  return (
    <PackagesListContainer data-testid="packages-container">
      <InfiniteContent packageCategory={packageCategory} />
    </PackagesListContainer>
  );
};
