import React from 'react';
import {
  ContentContainer,
  LabelImageContainer,
  StyledImage,
  Subtitle,
  TextContainer,
  Title,
  TrackingNumberContainer,
  LabelImageTextContainer,
  LabelImageSubtitle,
} from './styles';
import { PackageCategoryEnum } from 'store/packages/types';
import { usePackageDetailsContent } from './hooks/use-package-details-content.hook';
import { Viewer } from 'components/viewer';
import { useIntl } from 'react-intl';
import { TrackingNumberToast } from './tracking-number-toast';
import ContentCopySvg from 'assets/content-copy.svg';

interface PackageDetailsContentProps {
  packageCategory: PackageCategoryEnum;
}

export const PackageDetailsContent = ({ packageCategory }: PackageDetailsContentProps): JSX.Element => {
  const intl = useIntl();
  const {
    packageDateInfo,
    onImageClick,
    textToCopy,
    onIFrameClick,
    carrier,
    tracking_number,
    label_url,
    isToastVisible,
    toggleToastVisibility,
    isIncomingPackage,
  } = usePackageDetailsContent(packageCategory);

  return (
    <ContentContainer data-testid="content-container" data-cy="content-container">
      <Title>{intl.formatMessage({ id: 'carrier' })}</Title>
      <Subtitle>{carrier}</Subtitle>
      <TrackingNumberContainer>
        <TextContainer>
          <Title>{intl.formatMessage({ id: 'tracking' })}</Title>
          <Subtitle ref={textToCopy}>{tracking_number}</Subtitle>
        </TextContainer>
        {isIncomingPackage && (
          <StyledImage data-testid="copy-icon" onClick={onImageClick} src={ContentCopySvg} alt="clickable-copy-icon" />
        )}
      </TrackingNumberContainer>
      <Title>{intl.formatMessage({ id: 'packageDateInfo' }, { packageCategory })}</Title>
      <Subtitle>{packageDateInfo}</Subtitle>
      {label_url && (
        <LabelImageContainer>
          <LabelImageTextContainer>
            <Title>{intl.formatMessage({ id: 'labelImage' })}</Title>
            <LabelImageSubtitle>{intl.formatMessage({ id: 'clickToEnlarge' })}</LabelImageSubtitle>
          </LabelImageTextContainer>
          <Viewer url={label_url} onClick={onIFrameClick} isPackageDetailsPage />
        </LabelImageContainer>
      )}
      {isToastVisible && <TrackingNumberToast toggleToastVisibility={toggleToastVisibility} />}
    </ContentContainer>
  );
};
