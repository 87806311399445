import { ToggleBlock } from '@hqo/react-components-library/dist/molecules/toggle-block';
import { PageTitle } from '@hqo/react-components-library/dist/page-header';
import { useThemeFont } from 'hooks/use-theme-font.hook';
import React from 'react';
import { useIntl } from 'react-intl';
import { FontLocation } from 'shared/consts';
import { StyledContainer } from './styles';
import { Packages } from './packages';
import { usePackageListContent } from './hooks/use-package-list-content.hook';
import { PackageCategoryEnum } from 'store/packages/types';
import { Route, Routes } from 'react-router-dom';
import { PackageDetails } from '../../pages/package-details';

interface PackagesListContentProps {
  packageCategory: PackageCategoryEnum;
}

export const PackagesListContent = ({ packageCategory }: PackagesListContentProps): JSX.Element => {
  const themeFontTitle = useThemeFont(FontLocation.TITLE);
  const intl = useIntl();
  const { isIncomingPackages, onToggleChange } = usePackageListContent(packageCategory);

  return (
    <StyledContainer>
      <PageTitle data-testid="title" font={themeFontTitle}>{intl.formatMessage({ id: 'packageListTitle' })}</PageTitle>
      <ToggleBlock
        leftLabel={intl.formatMessage({ id: 'toggleLeftLabel' })}
        rightLabel={intl.formatMessage({ id: 'toggleRightLabel' })}
        isLeftActive={isIncomingPackages}
        isRightEnabled
        isLeftEnabled
        onLeftLabelClick={onToggleChange}
        onRightLabelClick={onToggleChange}
        className="toggle-block"
      />
      <Packages packageCategory={packageCategory} />
      <Routes>
        <Route
          path="/:packageId/*"
          element={
            <PackageDetails packageCategory={packageCategory} />
          }
        />
      </Routes>
    </StyledContainer>
  );
};
