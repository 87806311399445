import {
  CONTENT_OFFSET_BOTTOM,
  EXTRA_CONTENT_HEIGHT,
  PACKAGE_TILE_HEIGHT,
  INITIAL_PACKAGES_SET,
} from 'components/packages-list-content/package-list-content.const';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { selectBuildingUuid } from 'store/building/selectors';
import { getPackages } from 'store/packages/actions';
import { selectGetPackagesStatus, selectPackages, selectPaginationParams } from 'store/packages/selectors';
import { OwnerTypesEnum, PackageCategoryEnum } from 'store/packages/types';

export interface UseInfiniteContentProps {
  packageCategory: PackageCategoryEnum;
}

export const useInfiniteContent = ({ packageCategory }: UseInfiniteContentProps) => {
  const limitOfElements = Math.round(
    Math.abs((document.documentElement.clientHeight - EXTRA_CONTENT_HEIGHT) / PACKAGE_TILE_HEIGHT),
  );
  const dispatch = useDispatch();
  const buildingUuid = useSelector(selectBuildingUuid);
  const { total, count } = useSelector(selectPaginationParams);
  const infinitePackages = useSelector((state) => selectPackages(state, packageCategory));
  const isInfinitePackagesLoading = useSelector(selectGetPackagesStatus) === ACTION_STATUSES.PENDING;
  const isLastPackages = total === count;
  const onScroll = useCallback((): void => {
    const { pageYOffset } = window || {};
    const { clientHeight, scrollHeight } = document.documentElement || {};

    if (
      pageYOffset + clientHeight > scrollHeight - CONTENT_OFFSET_BOTTOM
      && !isInfinitePackagesLoading
      && !isLastPackages
    ) {
      const offset = limitOfElements * (Object.keys(infinitePackages).length + INITIAL_PACKAGES_SET);
      dispatch(
        getPackages.request({
          type: packageCategory,
          ownerType: OwnerTypesEnum.BUILDING,
          ownerId: buildingUuid,
          indexOfSet: Object.keys(infinitePackages).length + INITIAL_PACKAGES_SET,
          limit: limitOfElements,
          offset,
        }),
      );
    }
  }, [
    buildingUuid,
    isLastPackages,
    dispatch,
    infinitePackages,
    isInfinitePackagesLoading,
    limitOfElements,
    packageCategory,
  ]);

  useEffect(() => {
    if (!isLastPackages) {
      window.addEventListener('scroll', onScroll);
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll, isLastPackages]);
};
