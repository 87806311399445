import styled from 'styled-components';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';

const DEFAULT_MARGINS_TOTAL = 88;

export const StyledSwipeModal = styled(SwipeModal)<{ isImageViewerPage: boolean }>`
  .content-wrapper {
    ${({ isImageViewerPage }) => (isImageViewerPage
      && `margin-top: 60px;
      height: calc(100vh - ${DEFAULT_MARGINS_TOTAL}px);
      `
  )};
  }
`;
