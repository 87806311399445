export interface SwitchComponentProps {
  children: Array<JSX.Element>;
  value: string;
}

export const SwitchComponent = ({ children, value }: SwitchComponentProps): JSX.Element => {
  if (!value) return null;

  return children.find((child) => child.props['data-value'] === value) ?? null;
};
