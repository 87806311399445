import { MILLISECONDS_PER_SECOND } from 'shared/consts/time';

export enum FORMAT {
  TIME = 'time',
  MONTH_DAY = 'month_day',
  MONTH_DAY_TIME = 'month_day_time',
}

export const formatEpochTo = (epochTimestamp: number, locale: string, format: FORMAT): string => {
  const date = new Date(epochTimestamp * MILLISECONDS_PER_SECOND);
  const monthDay = date.toLocaleString(locale, { month: 'long', day: 'numeric' });
  const time = date.toLocaleString(locale, { hour: 'numeric', minute: '2-digit' }).toLowerCase().replace(/\s/g, '');

  switch (format) {
  case FORMAT.TIME:
    return time;
  case FORMAT.MONTH_DAY:
    return monthDay;
  case FORMAT.MONTH_DAY_TIME:
    return `${monthDay}, ${time}`;
  default:
    return format;
  }
};
