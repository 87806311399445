import { RootDependencies, rootDependencies } from 'store/dependencies';
import { applyMiddleware, createStore } from 'redux';
import rootReducer, { RootState } from './reducer';

import { RootAction } from 'store/actions';
import { StateType } from 'typesafe-actions';
import { composeEnhancers } from 'store/utils';
import { createEpicMiddleware } from 'redux-observable';
import { createReduxEnhancer } from '@sentry/react';
import rootEpic from './epics';
import { sentryStateTransformer } from './utils/sentry-state-transformer.util';

const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: sentryStateTransformer,
});

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, RootDependencies>({
  dependencies: rootDependencies,
});
const enhancer = composeEnhancers(applyMiddleware(epicMiddleware), sentryReduxEnhancer);
const initialState = {};
export const store = createStore(rootReducer, initialState, enhancer);

epicMiddleware.run(rootEpic);

export type Store = StateType<typeof store>;
