import * as actions from './actions';

import {
  catchError, filter, map, switchMap, withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { GetPackagesAPIResponse } from './types';

export const getPackagesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getPackages.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .getPackages(payload)
    .pipe(
      map(({ response }: AjaxResponse<GetPackagesAPIResponse>) => actions.getPackages.success({
        packages: response.data.packages,
        type: payload.type,
        indexOfSet: payload.indexOfSet,
        total: response.total,
        count: response.count,
      })),
      catchError((error: Error) => of(actions.getPackages.failure({ error }))),
    )),
);
