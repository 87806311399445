import { PackageDetailsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const packageDetailsSelector = (state: RootState): PackageDetailsState => state.packageDetails;

export const selectPackageDetails = createSelector(
  [packageDetailsSelector],
  (packageDetails) => packageDetails.package,
);

export const selectGetPackageDetailsStatus = createSelector(
  [packageDetailsSelector],
  (packageDetails) => packageDetails.getPackageDetails.status,
);

export const selectGetPackageDetailsError = createSelector(
  [packageDetailsSelector],
  (packageDetails) => packageDetails.getPackageDetails.error,
);
