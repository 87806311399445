import { MessageFormatElement } from 'react-intl';
import { ERROR_DEFAULT } from 'shared/consts/error-codes';
import {
  ErrorType,
  ERROR_TRANSLATION_BUTTON_TEXT_SUFFIX,
  ERROR_TRANSLATION_DESCRIPTION_SUFFIX,
  ERROR_TRANSLATION_PREFIX,
  ERROR_TRANSLATION_TITLE_SUFFIX,
} from 'shared/consts/error-translations';
import { ErrorNotification as ErrorNotificationData } from 'store/errors/types';
import { translationKey } from 'utils/translation-key';

interface ErrorDetails {
  errorCode: string;
  titleTranslationId: string;
  descriptionTranslationId: string;
  buttonTextTranslationId: string;
}

function getTranslationPrefix(errorType: ErrorType, errorCode: string): string {
  return translationKey(ERROR_TRANSLATION_PREFIX, errorType, errorCode);
}

export function getErrorDetails(
  errors: Pick<ErrorNotificationData, 'errorCode'>[],
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
  errorType: ErrorType,
): ErrorDetails {
  const errorCode = errors[0]?.errorCode?.toLowerCase();
  const errorTranslationPrefix = getTranslationPrefix(errorType, errorCode);
  const hasTranslationForErrorCode = Object.prototype.hasOwnProperty.call(
    messages,
    translationKey(errorTranslationPrefix, ERROR_TRANSLATION_TITLE_SUFFIX),
  );
  const translationPrefix = hasTranslationForErrorCode
    ? errorTranslationPrefix
    : getTranslationPrefix(errorType, ERROR_DEFAULT);

  return {
    errorCode: hasTranslationForErrorCode ? errorCode : ERROR_DEFAULT,
    titleTranslationId: translationKey(translationPrefix, ERROR_TRANSLATION_TITLE_SUFFIX),
    descriptionTranslationId: translationKey(translationPrefix, ERROR_TRANSLATION_DESCRIPTION_SUFFIX),
    buttonTextTranslationId: translationKey(translationPrefix, ERROR_TRANSLATION_BUTTON_TEXT_SUFFIX),
  };
}
