import React from 'react';
import { Package, PackageCategoryEnum } from 'store/packages/types';
import { PackageItemTile } from 'components/package-item-tile';
import {
  InfoText, PackageItemContainer, Title, TrackingNumber, TrackingText,
} from './styles';
import { STATUS_PILL_VARIANT, StatusPill } from '@hqo/react-components-library';
import { getStatusInfo } from './utils/getStatusInfo';
import { useIntl } from 'react-intl';
import { usePackageItem } from './hooks/use-package-item.hook';

interface PackageItemProps {
  packageItem: Package;
  packageCategory: PackageCategoryEnum;
}

export const PackageItem = ({ packageItem, packageCategory }: PackageItemProps): JSX.Element => {
  const intl = useIntl();
  const {
    id, sender_name, status, tracking_number, carrier, recipient_name,
  } = packageItem || {};

  const { label, color } = getStatusInfo(status, intl) ?? {};

  const { date, time, onPackageItemClick } = usePackageItem(packageItem);

  const name = packageCategory === PackageCategoryEnum.INCOMING ? sender_name : recipient_name;

  return (
    <PackageItemTile
      onClick={onPackageItemClick}
      dataCy={`package-item-tile-${id}`}
      data-testid="package-item-tile"
    >
      <PackageItemContainer>
        <Title>{intl.formatMessage({ id: 'packageTitle' }, { packageCategory, name })}</Title>
        <TrackingText>
          {intl.formatMessage({ id: 'trackingNumber' }, { number: <TrackingNumber>{tracking_number}</TrackingNumber> })}
        </TrackingText>
        <InfoText>
          {intl.formatMessage({ id: 'packageInfo' }, {
            carrier, date, time, packageCategory,
          })}
        </InfoText>
        <StatusPill color={color} status={label} variant={STATUS_PILL_VARIANT.ROUNDED} />
      </PackageItemContainer>
    </PackageItemTile>
  );
};
