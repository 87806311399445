import { InitHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { useMiniappSdkEventHandler } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { useDispatch } from 'react-redux';
import { getBuilding } from 'store/building/actions';
import { getBuildingTheme } from 'store/theme/actions';
import { getCurrentUser } from 'store/user/actions';

export const useSdkData = (): void => {
  const dispatch = useDispatch();

  useMiniappSdkEventHandler(
    'initResponse',
    (data: InitHandlerPayload) => {
      if (data?.user) {
        dispatch(getCurrentUser.success({ user: data.user }));
      }
      if (data?.building) {
        dispatch(getBuilding.success(data.building));
      }
      if (data?.theme) {
        dispatch(getBuildingTheme.success(data.theme));
      }
    },
    [],
  );
};
