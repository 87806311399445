import { Route, Routes } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { AppRoot } from 'components/app-root';
import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import React from 'react';
import { PackagesList } from 'pages/packages-list';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Routes>
      <Route path="/packages/*" element={<PackagesList />} />
      <Route path="/" element={<AppRoot />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route element={<DefaultRedirect />} />
    </Routes>
  </AppContentWrapper>
);
