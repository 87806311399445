import {
  MappedPackageStatusColor,
  PackageStatusEnum,
  StatusInfo,
} from 'store/packages/types';
import { IntlShape } from 'react-intl';

export const getStatusInfo = (status: PackageStatusEnum, intl: IntlShape): StatusInfo | null => {
  switch (status) {
  case PackageStatusEnum.PACKAGE_ACCEPTED:
    return {
      color: MappedPackageStatusColor[PackageStatusEnum.PACKAGE_ACCEPTED],
      label: intl.formatMessage({ id: 'packageAccepted' }),
    };
  case PackageStatusEnum.PICKUP_AVAILABLE:
    return {
      color: MappedPackageStatusColor[PackageStatusEnum.PICKUP_AVAILABLE],
      label: intl.formatMessage({ id: 'readyForPickup' }),
    };
  case PackageStatusEnum.PICKED_UP:
    return {
      color: MappedPackageStatusColor[PackageStatusEnum.PICKED_UP],
      label: intl.formatMessage({ id: 'pickedUp' }),
    };
  case PackageStatusEnum.DELIVERED:
    return {
      color: MappedPackageStatusColor[PackageStatusEnum.DELIVERED],
      label: intl.formatMessage({ id: 'pickedUp' }),
    };
  default:
    return null;
  }
};
