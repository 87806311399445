import { PaginatedResponse, PaginationParams } from 'shared/types/pagination';

export enum OwnerTypesEnum {
  COMPANY = 'COMPANY',
  BUILDING = 'BUILDING',
}

export enum PackageCategoryEnum {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export interface GetPackagesParams extends PaginationParams {
  ownerType: OwnerTypesEnum;
  ownerId: string;
  type: PackageCategoryEnum;
  indexOfSet: number;
}

export interface PackageCategory {
  type: PackageCategoryEnum;
}

export interface GetPackagesAPIResponse {
  data: GetPackagesResponse;
  total: number;
  count: number;
  paging: PaginatedResponse;
}

export interface GetPackagesResponse extends PackageCategory {
  packages: Array<Package>;
  indexOfSet?: number;
  total: number;
  count: number;
}

export interface Package {
  id: string;
  type: PackageTypeEnum;
  tracking_number: string;
  tracking_url: string;
  status: PackageStatusEnum;
  created_at: number;
  label_url: string;
  sender_name?: string;
  recipient_name?: string;
  carrier?: string;
}

export enum PackageTypeEnum {
  INBOUND_PACKAGE = 'INBOUND_PACKAGE',
  OUTBOUND_PACKAGE = 'OUTBOUND_PACKAGE',
}

export enum PackageStatusEnum {
  PACKAGE_ACCEPTED = 'package_accepted',
  PICKUP_AVAILABLE = 'pickup_available',
  PICKED_UP = 'picked_up',
  DELIVERED = 'delivered',
}

export interface PackagesState {
  incoming: { [key: number]: Array<Package> };
  outgoing: { [key: number]: Array<Package> };
  getInfinitePackages: AsyncActionTypes;
  total: number;
  count: number;
}

export type StatusInfo = { color: string; label: string };

export const MappedPackageStatusColor : Record<PackageStatusEnum, string> = {
  [PackageStatusEnum.PACKAGE_ACCEPTED]: '#4661B6',
  [PackageStatusEnum.PICKUP_AVAILABLE]: '#D55021',
  [PackageStatusEnum.PICKED_UP]: '#09794E',
  [PackageStatusEnum.DELIVERED]: '#09794E',
};

export enum ModalContentType {
  PACKAGE_DETAILS = 'PACKAGE_DETAILS',
  IMAGE_VIEWER = 'IMAGE_VIEWER',
}
