import { UiMetadataState } from 'store/ui-metadata/types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { fetchUiMetadata } from 'store/ui-metadata/actions';

export const initialState: UiMetadataState = {
  data: null,
  error: null,
  loading: false,
};

const loading = createReducer(initialState.loading)
  .handleAction(fetchUiMetadata.request, () => true)
  .handleAction(fetchUiMetadata.success, () => false)
  .handleAction(fetchUiMetadata.failure, () => false);

const error = createReducer(initialState.error)
  .handleAction(fetchUiMetadata.request, (state) => state)
  .handleAction(fetchUiMetadata.success, () => null)
  .handleAction(fetchUiMetadata.failure, (_state, action) => action.payload.error);

const data = createReducer(initialState.data)
  .handleAction(fetchUiMetadata.request, (state) => state)
  .handleAction(fetchUiMetadata.success, (_state, action) => action.payload)
  .handleAction(fetchUiMetadata.failure, () => null);

export default combineReducers({ loading, error, data });
