import { useEffect } from 'react';
import { setUser as setSentryUser } from '@sentry/react';
import { useSentryAnalytics } from 'hooks/use-sentry-analytics.hook';
import { useWebTrackingAnalytics } from './use-web-tracking.hook';
import { initializeWebTracking } from '@hqo/web-tracking';

export const useAnalytics = (): void => {
  const analytics = useSentryAnalytics();
  const webTrackingData = useWebTrackingAnalytics();

  useEffect(() => {
    setSentryUser(analytics);
  }, [analytics]);

  useEffect(() => {
    if (webTrackingData?.currentUser && webTrackingData?.buildingUuid) {
      initializeWebTracking(
        webTrackingData?.authToken,
        webTrackingData?.buildingUuid,
        webTrackingData?.appUuid,
        navigator?.userAgent,
        webTrackingData?.clientApiUrl,
      );
    }
  }, [
    webTrackingData?.appUuid,
    webTrackingData?.buildingUuid,
    webTrackingData?.authToken,
    navigator?.userAgent,
    webTrackingData?.clientApiUrl,
  ]);
};
