import { ErrorsState, ErrorNotification } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const errorsSelect = (state: RootState): RootState['errors'] => state.errors;

export const selectErrorNotifications = createSelector(
  errorsSelect,
  (state: ErrorsState): ErrorNotification[] => state.errors,
);

export const selectGenericErrorNotifications = createSelector(
  errorsSelect,
  (state: ErrorsState): ErrorNotification[] => state.genericErrors,
);
