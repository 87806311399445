import React from 'react';
import { PackageCategoryEnum } from 'store/packages/types';
import { useIntl } from 'react-intl';
import { usePackageDetails } from './hooks/use-package-details.hook';
import { ModalContent } from './components/modal-content';
import { StyledSwipeModal } from './styles';

interface PackageDetailsProps {
  packageCategory: PackageCategoryEnum;
}

export const PackageDetails = ({ packageCategory }: PackageDetailsProps): JSX.Element => {
  const intl = useIntl();
  const {
    name, isImageViewerPage, onPressBack, onClose,
  } = usePackageDetails(packageCategory);
  const title = name && !isImageViewerPage
    && intl.formatMessage({ id: 'packageDetailsTitle' }, { packageCategory, name });

  return (
    <StyledSwipeModal
      title={title}
      isImageViewerPage={isImageViewerPage}
      reverseAnimation={false}
      onClose={onClose}
      onPressBack={onPressBack}
      withLeftArrowButton={isImageViewerPage}
      content={<ModalContent packageCategory={packageCategory} />}
    />
  );
};
