import { useDispatch, useSelector } from 'react-redux';
import { selectBuildingUuid } from 'store/building/selectors';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { OwnerTypesEnum, PackageCategoryEnum } from 'store/packages/types';
import { getPackages, resetPackages } from 'store/packages/actions';
import { replace } from 'store/router/actions';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';
import {
  EXTRA_CONTENT_HEIGHT,
  INITIAL_PACKAGES_SET,
  PACKAGE_TILE_HEIGHT,
} from 'components/packages-list-content/package-list-content.const';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';

interface UsePackageListContentReturnValues {
  isIncomingPackages: boolean;
  onToggleChange: VoidFunction;
}

export const usePackageListContent = (packageCategory: PackageCategoryEnum): UsePackageListContentReturnValues => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const buildingUuid = useSelector(selectBuildingUuid);
  const isIncomingPackages = packageCategory === PackageCategoryEnum.INCOMING;
  const client = useMiniappSdk();

  useEffect(() => {
    client?.header.setHeader('', HEADER_BUTTON_MODE.CLOSE);
    client?.header.showHeader();
  }, [client]);

  useEffect(() => {
    const limitOfElements = Math.round(
      Math.abs((document.documentElement.clientHeight - EXTRA_CONTENT_HEIGHT) / PACKAGE_TILE_HEIGHT),
    );
    if (buildingUuid) {
      dispatch(
        getPackages.request({
          type: packageCategory,
          ownerType: OwnerTypesEnum.BUILDING,
          ownerId: buildingUuid,
          indexOfSet: INITIAL_PACKAGES_SET,
          limit: limitOfElements,
        }),
      );
    }
  }, [packageCategory]);

  const onToggleChange = useCallback(() => {
    const replaceValue = isIncomingPackages ? PackageCategoryEnum.OUTGOING : PackageCategoryEnum.INCOMING;
    dispatch(resetPackages());
    track(
      TRACK_EVENT_NAMES.PACKAGE_TOGGLE_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
      },
      { sendToHqoTracking: true },
    );
    dispatch(replace(`${pathname.replace(packageCategory, replaceValue)}${search}`));
  }, [isIncomingPackages, search, pathname]);

  return { isIncomingPackages, onToggleChange };
};
