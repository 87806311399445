import { externalLogin } from 'store/user/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from './use-search-params.hook';

type AuthParams = { authToken?: string; apiUrl?: string };

export const useExternalLogin = (): boolean => {
  const dispatch = useDispatch();
  const { authToken, apiUrl } = useSearchParams<AuthParams>();

  const shouldWaitForLogin = !!authToken || !!apiUrl;

  useEffect((): void => {
    if (shouldWaitForLogin) {
      dispatch(externalLogin.request({ authToken: authToken ?? null, apiUrl: apiUrl ?? null }));
    }
  }, [dispatch, externalLogin, shouldWaitForLogin]);

  return shouldWaitForLogin;
};
