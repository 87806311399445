import { PackageCategoryEnum, PackagesState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const packagesSelector = (state: RootState): PackagesState => state.packages;

export const selectPackages = createSelector(
  [packagesSelector, (_state, packageCategory: PackageCategoryEnum) => packageCategory],
  (packages, packageCategory) => packages[packageCategory],
);

export const selectPackageById = createSelector(
  [selectPackages, (_state, _packageCategory, packageId) => packageId],
  (packages, packageId) => {
    const allPackages = Object.values(packages).flat();

    return allPackages.find((pkg) => pkg?.id === packageId) || null;
  },
);

export const selectGetPackagesStatus = createSelector(
  [packagesSelector],
  (packages) => packages.getInfinitePackages.status,
);

export const selectGetPackagesError = createSelector(
  [packagesSelector],
  (packages) => packages.getInfinitePackages.error,
);

export const selectPaginationParams = createSelector(
  [packagesSelector],
  (packages) => ({ total: packages.total, count: packages.count }),
);
