import { EMPTY, filter, switchMap } from 'rxjs';
import {
  goBack,
  goForward,
  push,
  replace,
} from 'store/router/actions';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { history } from './history';
import { isActionOf } from 'typesafe-actions';

export const pushEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) => action$.pipe(
  filter(isActionOf(push)),
  switchMap((action) => {
    history.push(action.payload);

    return EMPTY;
  }),
);

export const replaceEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) => action$.pipe(
  filter(isActionOf(replace)),
  switchMap((action) => {
    history.replace(action.payload);

    return EMPTY;
  }),
);

export const goBackEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) => action$.pipe(
  filter(isActionOf(goBack)),
  switchMap(() => {
    history.back();

    return EMPTY;
  }),
);

export const goForwardEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) => action$.pipe(
  filter(isActionOf(goForward)),
  switchMap(() => {
    history.forward();

    return EMPTY;
  }),
);
