import React from 'react';
import { Wrapper } from './styles';
import { ModalContentType, PackageCategoryEnum } from 'store/packages/types';
import { useSelector } from 'react-redux';
import { selectGetPackageDetailsStatus } from 'store/package-details/selectors';
import { Spinner } from 'components/spinner';
import { ACTION_STATUSES } from 'shared/consts';
import { SwitchContent } from './switch-content';
import { useLocation } from 'react-router';

interface ModalContentProps {
  packageCategory: PackageCategoryEnum;
}

export const ModalContent = ({
  packageCategory,
}: ModalContentProps): JSX.Element => {
  const packageDetailsStatus = useSelector(selectGetPackageDetailsStatus);
  const isContentLoaded = packageDetailsStatus === ACTION_STATUSES.FULFILLED
    || packageCategory === PackageCategoryEnum.OUTGOING;
  const { pathname } = useLocation();
  const isImageViewerPage = pathname.includes('label');
  const modalContentType = isImageViewerPage ? ModalContentType.IMAGE_VIEWER : ModalContentType.PACKAGE_DETAILS;

  return (
    <Wrapper>
      {isContentLoaded ? (
        <SwitchContent packageCategory={packageCategory} modalContentType={modalContentType} />
      ) : <Spinner />}
    </Wrapper>
  );
};
