import { createAction, createAsyncAction } from 'typesafe-actions';
import { GetPackagesResponse, GetPackagesParams } from './types';
import { FailureActionPayload } from 'store/errors/types';

export const getPackages = createAsyncAction('GET_PACKAGES_REQUEST', 'GET_PACKAGES_SUCCESS', 'GET_PACKAGES_FAILURE')<
  GetPackagesParams,
  GetPackagesResponse,
  FailureActionPayload
>();

export const resetPackages = createAction('RESET_PACKAGES')<void>();
