import styled from 'styled-components';

export const Title = styled.div`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
  text-align: left;
`;

export const InfoText = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  text-align: left;
`;

export const TrackingText = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  width: 100%;
  white-space: nowrap;
`;

export const TrackingNumber = styled.p`
  margin: 0;
  font: 400 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PackageItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
`;
