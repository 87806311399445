import {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { HqoClientSdk, Handler, SdkEvents } from '@hqo/hqo-miniapp-client-sdk';
import { MiniappSdkContext } from 'components/miniapp-sdk-provider/miniapp-sdk.context';

export const useMiniappSdkClient = (appUuid: string): HqoClientSdk => useMemo(
  () => new HqoClientSdk({
    develop: false,
    app_uuid: appUuid,
    init_timeout: null,
  }),
  [appUuid],
);

export const useMiniappSdk = (): HqoClientSdk | null => useContext(MiniappSdkContext);

export const useMiniappSdkEventHandler = (
  eventName: SdkEvents,
  handler: Handler,
  handlerDependencies: unknown[],
): void => {
  const client = useMiniappSdk();
  const eventHandler = useCallback(handler, handlerDependencies);

  useEffect(() => {
    client?.on(eventName, eventHandler);
  }, [eventName, eventHandler]);
};
