import styled from 'styled-components';

interface StyledIFrameProps {
  isLoading: boolean;
  isPackageDetailsPage: boolean;
}

export const PDFViewerContainer = styled.div<{ isPackageDetailsPage: boolean }>`
  width: 100%;
  height: ${({ isPackageDetailsPage }) => (isPackageDetailsPage ? '400px' : '100%')};
`;

export const StyledIFrame = styled.iframe<StyledIFrameProps>`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  width: 100%;
  height: 100%;
  border: 0;
  pointer-events: ${({ isPackageDetailsPage }) => (isPackageDetailsPage ? 'none' : 'auto')};
`;

export const ClickedArea = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: auto;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const StyledImage = styled.img`
    width: 100%;
    transition: transform 0.3s ease;
`;
