import { useDispatch, useSelector } from 'react-redux';
import { selectBuildingLocale } from 'store/building/selectors';
import { useCallback } from 'react';
import { FORMAT, formatEpochTo } from 'utils/date-and-time/formatEpochTo';
import { Package, PackageTypeEnum } from 'store/packages/types';
import { useLocation } from 'react-router';
import { replace } from 'store/router/actions';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';

interface UsePackageItemReturnValues {
  date: string;
  time: string;
  onPackageItemClick: VoidFunction;
}

export const usePackageItem = (packageItem: Package): UsePackageItemReturnValues => {
  const locale = useSelector(selectBuildingLocale);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const { id, created_at } = packageItem || {};

  const date = formatEpochTo(created_at, locale, FORMAT.MONTH_DAY);
  const time = formatEpochTo(created_at, locale, FORMAT.TIME);

  const onPackageItemClick = useCallback(() => {
    track(
      packageItem.type === PackageTypeEnum.INBOUND_PACKAGE
        ? TRACK_EVENT_NAMES.PACKAGE_DELIVERED_LIST_CLICK
        : TRACK_EVENT_NAMES.PACKAGE_DROP_OFF_LIST_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        status: packageItem.status,
      },
      { sendToHqoTracking: true },
    );

    dispatch(replace(`${pathname}/${id}${search}`));
  }, [pathname, id, search, dispatch]);

  return { date, time, onPackageItemClick };
};
