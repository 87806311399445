export const LAUNCH_DARKLY_ANONYMOUS_EMAIL = 'anonymous@hqo.co';
export const BUILDING = 'BUILDING';
export const VERTICAL_NAME = 'PACKAGE_MANAGEMENT';

export enum TRACK_EVENT_NAMES {
  PACKAGE_TOGGLE_CLICK = 'package_toggle_click',
  PACKAGE_LIST_IMPRESSION = 'package_list_impression',
  PACKAGE_DROP_OFF_LIST_CLICK = 'package_drop_off_list_click',
  PACKAGE_DELIVERED_LIST_CLICK = 'package_delivered_list_click'
}

export enum TRACK_EVENT_TYPES {
  ACTION = 'action',
  IMPRESSION = 'impression',
}
