import React from 'react';
import { StyledPackageItemTile } from './styles';

interface PackageListSkeletonProps {
  tileQuantity: number;
}

export const PackageListSkeleton = ({ tileQuantity }: PackageListSkeletonProps) => (
  <>
    {Array.from(Array(tileQuantity).keys()).map((tileIndex) => (
      <StyledPackageItemTile key={tileIndex} data-testid="package-item-tile" />
    ))}
  </>
);
