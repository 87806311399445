import { useDispatch, useSelector } from 'react-redux';
import { selectBuildingUuid } from 'store/building/selectors';
import { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { selectPackageDetails } from 'store/package-details/selectors';
import { OwnerTypesEnum, PackageCategoryEnum } from 'store/packages/types';
import { getPackageDetails, resetPackageDetails } from 'store/package-details/actions';
import { replace } from 'store/router/actions';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { selectPackageById } from 'store/packages/selectors';

interface UsePackageDetailsReturnValues {
  name: string;
  isImageViewerPage: boolean;
  onPressBack: VoidFunction;
  onClose: VoidFunction;
}

export const usePackageDetails = (packageCategory: PackageCategoryEnum): UsePackageDetailsReturnValues => {
  const { packageId } = useParams();
  const client = useMiniappSdk();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const isIncomingPackage = packageCategory === PackageCategoryEnum.INCOMING;
  const isMobileDevice = useIsSmallViewportWidth();
  const buildingUuid = useSelector(selectBuildingUuid);
  const selectedPackageById = useSelector((state) => selectPackageById(state, packageCategory, packageId));
  const packageDetails = useSelector(selectPackageDetails);
  const selectedPackage = isIncomingPackage ? packageDetails : selectedPackageById;

  const { recipient_name, sender_name } = selectedPackage || {};

  const name = isIncomingPackage ? sender_name : recipient_name;
  const isImageViewerPage = pathname.includes('label');

  useEffect(() => {
    if (isMobileDevice) {
      client?.header.hideHeader();
      client?.swipe.disableBackSwipe();
    }

    return () => {
      client?.header.showHeader();
      client?.swipe.enableBackSwipe();
    };
  }, [client, isMobileDevice]);

  const onPressBack = useCallback(() => {
    dispatch(replace(`${pathname.replace('/label', '')}${search}`));
  }, [pathname, search]);

  const onClose = useCallback(() => {
    dispatch(resetPackageDetails());
    dispatch(replace(`${pathname.replace(`/${packageId}`, '')}${search}`));
  }, [pathname, packageId, search]);

  useEffect(() => {
    if (isIncomingPackage) {
      dispatch(getPackageDetails.request(
        {
          type: packageCategory, ownerType: OwnerTypesEnum.BUILDING, ownerId: buildingUuid, packageId,
        },
      ));
    }
  }, [packageCategory, buildingUuid, packageId, isIncomingPackage]);

  return {
    name, isImageViewerPage, onPressBack, onClose,
  };
};
