import styled from 'styled-components';
import { CircledCheckmark } from '@hqo/react-components-library';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  width: 100%;
`;

export const ImageViewerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const TrackingNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const LabelImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 6px;
`;

export const LabelImageTextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledImage = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
`;

export const Title = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const LabelImageSubtitle = styled.div`
  font: 400 12px/24px ${({ theme }) => theme.fonts.join()};
  font-style: italic;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const Subtitle = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledCircledCheckmark = styled(CircledCheckmark)`
  color: ${({ theme }) => theme.colors.admin.greenAlt};
  background-color: transparent;
`;

export const ToastMessageContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100% - 32px);
  padding: 0 16px 16px 16px;
`;
