import { RootState } from 'store/reducer';
import { UiMetadata } from 'store/ui-metadata/types';
import { createSelector } from 'reselect';

export const uiMetadataState = (state: RootState): RootState['uiMetadata'] => state.uiMetadata;
export const uiMetadata = createSelector(
  uiMetadataState,
  (state: RootState['uiMetadata']): UiMetadata | null => state.data,
);
export const uiMetadataLoadingState = createSelector(
  uiMetadataState,
  (state: RootState['uiMetadata']): boolean => state.loading,
);
