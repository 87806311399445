import React from 'react';
import { SwitchComponent } from 'hocs/switch-component';
import { PackageDetailsContent } from './package-details-content';
import { ImageViewer } from './image-viewer';
import { ModalContentType, PackageCategoryEnum } from 'store/packages/types';

interface SwitchContentProps {
  modalContentType: ModalContentType;
  packageCategory: PackageCategoryEnum;
}

export const SwitchContent = ({ modalContentType, packageCategory }: SwitchContentProps): JSX.Element => (
  <SwitchComponent value={modalContentType}>
    <PackageDetailsContent packageCategory={packageCategory} data-value={ModalContentType.PACKAGE_DETAILS} />
    <ImageViewer data-value={ModalContentType.IMAGE_VIEWER} />
  </SwitchComponent>
);
