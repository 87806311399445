import { PackagesListContent } from 'components/packages-list-content/packages-list-content';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PackageCategoryEnum } from 'store/packages/types';

export const PackagesList = () => (
  <Routes>
    <Route
      path="incoming/*"
      element={
        <PackagesListContent packageCategory={PackageCategoryEnum.INCOMING} />
      }
    />
    <Route
      path="outgoing/*"
      element={
        <PackagesListContent packageCategory={PackageCategoryEnum.OUTGOING} />
      }
    />
  </Routes>
);
