import React from 'react';

import { StyledPackageItemTile } from './styles';

interface PackageItemTileProps {
  onClick: VoidFunction;
  children: JSX.Element;
  dataCy: string;
  dataTestId?: string;
}

export const PackageItemTile = ({
  onClick, children, dataCy, dataTestId = 'package-item-tile',
}: PackageItemTileProps): JSX.Element => (
  <StyledPackageItemTile
    className="package-item-tile"
    data-cy={dataCy}
    data-testid={dataTestId}
    onClick={onClick}
  >
    {children}
  </StyledPackageItemTile>
);
