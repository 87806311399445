import { useDispatch, useSelector } from 'react-redux';
import { selectBuildingLocale } from 'store/building/selectors';
import {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { PackageCategoryEnum } from 'store/packages/types';
import { selectPackageDetails } from 'store/package-details/selectors';
import { FORMAT, formatEpochTo } from 'utils/date-and-time/formatEpochTo';
import { useLocation, useParams } from 'react-router';
import { replace } from 'store/router/actions';
import { AUTO_HIDE_TOAST_MS } from 'shared/consts/time';
import { useBooleanState } from '@hqo/react-components-library';
import { selectPackageById } from 'store/packages/selectors';

interface UsePackageDetailsContentReturnValues {
  carrier: string;
  label_url: string;
  tracking_number: string;
  packageDateInfo: string;
  onImageClick: VoidFunction;
  textToCopy: React.MutableRefObject<HTMLDivElement>;
  onIFrameClick: VoidFunction;
  isToastVisible: boolean;
  toggleToastVisibility: VoidFunction;
  isIncomingPackage: boolean;
}

export const usePackageDetailsContent = (
  packageCategory: PackageCategoryEnum,
): UsePackageDetailsContentReturnValues => {
  const { packageId } = useParams();
  const dispatch = useDispatch();
  const locale = useSelector(selectBuildingLocale);
  const textToCopy = useRef(null);
  const isIncomingPackage = packageCategory === PackageCategoryEnum.INCOMING;

  const { value: isToastVisible, toggle: toggleToastVisibility } = useBooleanState(false);

  const packageDetails = useSelector(selectPackageDetails);
  const selectedPackageById = useSelector((state) => selectPackageById(state, packageCategory, packageId));

  const selectedPackage = isIncomingPackage ? packageDetails : selectedPackageById;

  const { pathname, search } = useLocation();

  const {
    created_at, carrier, label_url, tracking_number,
  } = selectedPackage || {};

  const packageDateInfo = formatEpochTo(created_at, locale, FORMAT.MONTH_DAY_TIME);

  const onIFrameClick = useCallback(() => {
    dispatch(replace(`${pathname}/label${search}`));
  }, [pathname, search]);

  const onImageClick = useCallback(() => {
    if (textToCopy.current) {
      toggleToastVisibility();
      navigator.clipboard.writeText(textToCopy.current.innerText);
    }
  }, [textToCopy]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isToastVisible) {
      timeout = setTimeout(toggleToastVisibility, AUTO_HIDE_TOAST_MS);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isToastVisible, toggleToastVisibility]);

  return {
    carrier,
    label_url,
    tracking_number,
    onIFrameClick,
    packageDateInfo,
    onImageClick,
    textToCopy,
    isToastVisible,
    toggleToastVisibility,
    isIncomingPackage,
  };
};
