import { Navigate } from 'react-router';

import React from 'react';
import { useViewParams } from 'hooks/use-view-params.hook';
import { ROOT_PATH } from 'shared/consts';

export const AppRoot = (): JSX.Element => {
  const params = useViewParams();

  if (!params.buildingUuid) {
    return <Navigate to="/error" />;
  }

  return <Navigate to={ROOT_PATH} />;
};
