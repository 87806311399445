import { createAction, createAsyncAction } from 'typesafe-actions';
import { FailureActionPayload } from 'store/errors/types';
import { GetPackageDetailsResponse, GetPackageDetailsParams } from './types';

export const getPackageDetails = createAsyncAction(
  'GET_PACKAGE_DETAILS_REQUEST',
  'GET_PACKAGE_DETAILS_SUCCESS',
  'GET_PACKAGE_DETAILS_FAILURE',
)<GetPackageDetailsParams, GetPackageDetailsResponse, FailureActionPayload>();

export const resetPackageDetails = createAction('RESET_PACKAGE_DETAILS')<void>();
