import {
  useCallback, useRef, useState,
} from 'react';

interface UseViewerReturnValues {
  isLoading: boolean;
  customURLParams: string;
  imgRef: React.MutableRefObject<HTMLImageElement>;
  handleTap: VoidFunction;
  handleLoad: VoidFunction;
  startScale: number;
}

export const useViewer = (): UseViewerReturnValues => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const customURLParams = '#toolbar=0&navpanes=0&scrollbar=0';
  const [startScale, setStartScale] = useState<number>(1);
  const [lastTap, setLastTap] = useState<number>(0);

  const handleTap = useCallback(() => {
    const now = Date.now();
    const DOUBLE_TAP_DELAY = 300;

    if (now - lastTap < DOUBLE_TAP_DELAY) {
      const scale = startScale === 1 ? 2 : 1;
      setStartScale(scale);
      if (imgRef.current) {
        imgRef.current.style.transform = `scale(${scale})`;
      }
    }

    setLastTap(now);
  }, [lastTap, startScale]);

  const handleLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  return {
    imgRef, isLoading, handleLoad, handleTap, customURLParams, startScale,
  };
};
